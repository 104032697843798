import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/CloseRounded';

import BannerForm from '@components/pages/eventos/BannerForm';
import { Link } from '@components/ui';
import LiveAnimation from '@components/core/LiveAnimation';
import { Title } from '@components/core/Typography/index';

import { IEvent } from '@models/event.interface';

import { SubscribeConference } from '@services/conferences';

import { useAuth } from '@hooks/auth';
import { useUser } from '@hooks/user';
import { useTracking } from '@hooks/tracking';
import { useSnackbar } from '@hooks/snackbar';

import { AvatarGroup } from '@material-ui/lab';
import { Avatar } from '@material-ui/core';
import { getEventSpeakers } from '@services/squads';
import * as Styled from './styles';

export interface IEventCards {
  liveEvents: IEvent[];
  futureEvents: IEvent[];
  liveSpeakers: any[];
  futureSpeakers: any[];
  subscribeConferenceSuccess?: (slug: string) => void;
  onClickShare?: (eventSlug: string) => void;
}

export interface IHideText {
  text: string;
  maxChar?: number;
}

const defaultMax = 100;

const hideText = ({ text, maxChar }: IHideText) => {
  const maxCount = maxChar || defaultMax;
  if (text) {
    return (
      text.substring(0, maxCount).trim() +
      (text.length <= maxCount ? '' : '...')
    );
  }
  return '';
};

const EventCards: React.FC<IEventCards> = ({
  liveEvents = [],
  futureEvents = [],
  liveSpeakers = [],
  futureSpeakers = [],
}) => {
  const [actualEvent, setActualEvent] = useState<IEvent>({} as IEvent);
  const { showSnackbar } = useSnackbar();
  const [base64, setBase64] = useState('');

  const { user, userEvents } = useUser();
  const { setShowModalLogin } = useAuth();
  const { track } = useTracking();

  const [userSpeakers, setUserSpeakers] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = (event: IEvent) => {
    setOpen(true);
    setActualEvent(event);
  };
  const handleClose = () => setOpen(false);

  const router = useRouter();

  const [state, setState] = useState({
    checked: true,
  });

  useEffect(() => {
    const loadUserSpeakers = async () => {
      let speakers = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const event of userEvents) {
        // eslint-disable-next-line no-await-in-loop
        const speaker = await getEventSpeakers(event.id);
        speakers = [...speakers, speaker];
      }
      setUserSpeakers(speakers);
    };

    loadUserSpeakers();
  }, [userEvents]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    const { email } = user;
    const { firstName } = user;
    const { lastName } = user;
    const userSubscriptionBase64 = JSON.stringify({
      email,
      surname: lastName,
      firstname: firstName,
      createUserLandingPage: false,
      linkResetPassword: '',
    });

    const encode = Buffer.from(userSubscriptionBase64).toString('base64');

    setBase64(encode);
  }, [user]);

  const handleSubscribeConference = async () => {
    if (user?._id) {
      try {
        track('event-subscribe-conference-button-clicked', {
          eventSlug: actualEvent.slug,
        });
        await SubscribeConference.subscribeConference({
          eventID: actualEvent.id,
          userID: user._id,
        });

        router.push(
          `${process.env.NEXT_PUBLIC_APP}/eventos/${actualEvent.slug}?lead=${base64}`,
        );
        await SubscribeConference.updateHubspotProps(user, actualEvent.slug);
        showSnackbar({
          message: 'Parabéns ! \n Sua inscrição foi CONFIRMADA com sucesso !',
          type: 'success',
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      setShowModalLogin(true);
    }
  };
  return (
    <>
      <Styled.Container>
        {liveEvents.length === 0 &&
          futureEvents.length === 0 &&
          userEvents.length === 0 &&
          'Você ainda não fez cadastro em nenhum evento'}
        {liveEvents.length > 0 && (
          <div style={{ width: '100%' }}>
            <Title className="eventCardTitle eventCardTitleFirstChild">
              <LiveAnimation />
              Acontecendo agora
            </Title>
            <Styled.CardContainer>
              {liveEvents.map((event, eventIndex) => (
                <Styled.CardLink key={event.id}>
                  <Styled.Img
                    src={event.platformCard}
                    alt={event.name}
                    width="250px"
                    height="284px"
                    objectFit="cover"
                    quality="50"
                  />

                  <Styled.CardLinkGroup>
                    <h3>
                      {hideText({
                        text: event.name || '',
                        maxChar: 40,
                      })}
                    </h3>
                    <h4>
                      {hideText({
                        text: event.description || '',
                        maxChar: 98,
                      })}
                    </h4>
                    <h5>
                      {dayjs(event.startedAt).format('DD/MM')}
                      {' a '}
                      {dayjs(event.endedAt).format('DD/MM')}
                      {', das '}
                      {dayjs(event.startedAt).format('HH:mm')}
                      {' às '}
                      {dayjs(event.endedAt).format('HH:mm')}
                    </h5>

                    {liveSpeakers.length > eventIndex &&
                      liveSpeakers[eventIndex].length > 0 && (
                        <Styled.SpeakersInfo>
                          <AvatarGroup max={3}>
                            {liveSpeakers[eventIndex].map(speaker => (
                              <Avatar
                                style={{ zIndex: 0 }}
                                alt={speaker?.speakerProfile?.name}
                                src={speaker?.speakerProfile?.imageProfile}
                                key={speaker?.speakerProfile?.id}
                              />
                            ))}
                          </AvatarGroup>
                          <Styled.SpeakersName>
                            {liveSpeakers[eventIndex]
                              .map(speaker => {
                                if (
                                  !speaker.speakerProfile ||
                                  !speaker.speakerProfile.name
                                )
                                  return '';
                                return speaker.speakerProfile.name;
                              })
                              .join(', ')}
                          </Styled.SpeakersName>
                        </Styled.SpeakersInfo>
                      )}

                    <Styled.CardLinkGroupButtons>
                      {!userEvents.find(
                        userEvent => userEvent?.slug === event?.slug,
                      ) && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpen(event)}
                        >
                          Garanta sua vaga
                        </Button>
                      )}
                      <Link
                        href={`${process.env.NEXT_PUBLIC_APP}/eventos/detalhes/${event.slug}`}
                      >
                        Ver detalhes {'>'}
                      </Link>
                    </Styled.CardLinkGroupButtons>
                  </Styled.CardLinkGroup>

                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Styled.BoxWrapper>
                      <Styled.ModalLogo>
                        <img
                          src="https://startse-landings.s3.us-east-2.amazonaws.com/logos/startse-logo-s.svg"
                          alt="Logo StartSe"
                        />
                        <Styled.CloseButton onClick={handleClose}>
                          <CloseIcon fontSize="default" />
                        </Styled.CloseButton>
                      </Styled.ModalLogo>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.checked}
                              onChange={handleChange}
                              name="checked"
                              color="primary"
                            />
                          }
                          label="Eu concordo com os termos do SVWC 2021 - The NOWledge Festival"
                        />
                        <Styled.CenterButton>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubscribeConference()}
                          >
                            Finalizar inscrição
                          </Button>
                        </Styled.CenterButton>
                      </FormGroup>
                    </Styled.BoxWrapper>
                  </Modal>
                </Styled.CardLink>
              ))}
            </Styled.CardContainer>
          </div>
        )}
        {futureEvents.length > 0 && (
          <div style={{ width: '100%' }}>
            <Title className="eventCardTitle">Inscrições Abertas</Title>
            <Styled.CardContainer>
              {futureEvents.map((event, eventIndex) => {
                const BannerFormComponent = BannerForm[event.slug] as React.FC;

                return BannerFormComponent ? (
                  <BannerFormComponent key={event.id} />
                ) : (
                  <Styled.CardLink key={event.id}>
                    <Styled.Img
                      src={event.platformCard}
                      alt={event.name}
                      width="250px"
                      height="284px"
                      objectFit="cover"
                      quality="50"
                    />

                    <Styled.CardLinkGroup>
                      <h3>
                        {hideText({
                          text: event.name || '',
                          maxChar: 40,
                        })}
                      </h3>
                      <h4>
                        {hideText({
                          text: event.description || '',
                          maxChar: 98,
                        })}
                      </h4>
                      <h5>
                        {dayjs(event.startedAt).format('DD/MM')}
                        {' a '}
                        {dayjs(event.endedAt).format('DD/MM')}
                        {', das '}
                        {dayjs(event.startedAt).format('HH:mm')}
                        {' às '}
                        {dayjs(event.endedAt).format('HH:mm')}
                      </h5>

                      {futureSpeakers.length > eventIndex &&
                        futureSpeakers[eventIndex].length > 0 && (
                          <Styled.SpeakersInfo>
                            <AvatarGroup max={3}>
                              {futureSpeakers[eventIndex].map(speaker => (
                                <Avatar
                                  style={{ zIndex: 0 }}
                                  alt={speaker?.speakerProfile?.name}
                                  src={speaker?.speakerProfile?.imageProfile}
                                  key={speaker?.speakerProfile?.id}
                                />
                              ))}
                            </AvatarGroup>
                            <Styled.SpeakersName>
                              {futureSpeakers[eventIndex]
                                .map(speaker => {
                                  if (
                                    !speaker.speakerProfile ||
                                    !speaker.speakerProfile.name
                                  )
                                    return '';
                                  return speaker.speakerProfile.name;
                                })
                                .join(', ')}
                            </Styled.SpeakersName>
                          </Styled.SpeakersInfo>
                        )}

                      <Styled.CardLinkGroupButtons>
                        {!userEvents.find(
                          userEvent => userEvent?.slug === event?.slug,
                        ) && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleOpen(event)}
                          >
                            Garanta sua vaga
                          </Button>
                        )}
                        <Link
                          href={`${process.env.NEXT_PUBLIC_APP}/eventos/detalhes/${event.slug}`}
                        >
                          Ver detalhes {'>'}
                        </Link>
                      </Styled.CardLinkGroupButtons>
                    </Styled.CardLinkGroup>
                  </Styled.CardLink>
                );
              })}
            </Styled.CardContainer>
          </div>
        )}

        {userEvents.length > 0 && (
          <Styled.ContainerRegistered style={{ width: '100%' }}>
            <Title className="eventCardTitle">Eventos já Inscritos</Title>
            <Styled.CardContainer>
              {userEvents.map((event, eventIndex) => {
                const BannerFormComponent = BannerForm[event?.slug] as React.FC;

                return BannerFormComponent ? (
                  <BannerFormComponent key={event.id} />
                ) : (
                  <Styled.CardLink key={event.id}>
                    <Styled.Img
                      src={
                        event.platformCard
                          ? event.platformCard
                          : 'https://startse-uploader.s3.us-east-2.amazonaws.com/prod/squads/event/platform-card/5ee6d76538ed941b74c4fe90.png?v=d030b05e-176d-4d35-a7db-5538c1253385'
                      }
                      alt={event?.name}
                      width="250px"
                      height="284px"
                      objectFit="cover"
                      quality="50"
                    />

                    <Styled.CardLinkGroup>
                      <h3>
                        {hideText({
                          text: event?.name || '',
                          maxChar: 40,
                        })}
                      </h3>
                      <h4>
                        {hideText({
                          text: event?.description || '',
                          maxChar: 98,
                        })}
                      </h4>
                      <h5>
                        {dayjs(event?.startedAt).format('DD/MM')}
                        {' a '}
                        {dayjs(event?.endedAt).format('DD/MM')}
                        {', das '}
                        {dayjs(event?.startedAt).format('HH:mm')}
                        {' às '}
                        {dayjs(event?.endedAt).format('HH:mm')}
                      </h5>

                      {userSpeakers.length > eventIndex &&
                        userSpeakers[eventIndex].length > 0 && (
                          <Styled.SpeakersInfo>
                            <AvatarGroup max={3}>
                              {userSpeakers[eventIndex].map(speaker => (
                                <Avatar
                                  style={{ zIndex: 0 }}
                                  alt={speaker?.speakerProfile?.name}
                                  src={speaker?.speakerProfile?.imageProfile}
                                  key={speaker?.speakerProfile?.id}
                                />
                              ))}
                            </AvatarGroup>
                            <Styled.SpeakersName>
                              {userSpeakers[eventIndex]
                                .map(speaker => {
                                  if (
                                    !speaker.speakerProfile ||
                                    !speaker.speakerProfile.name
                                  )
                                    return '';
                                  return speaker.speakerProfile.name;
                                })
                                .join(', ')}
                            </Styled.SpeakersName>
                          </Styled.SpeakersInfo>
                        )}

                      <Styled.CardLinkGroupButtons
                        style={{ justifyContent: 'flex-end' }}
                      >
                        <Link
                          href={`${process.env.NEXT_PUBLIC_APP}/eventos/detalhes/${event?.slug}`}
                        >
                          Ver detalhes {'>'}
                        </Link>
                      </Styled.CardLinkGroupButtons>
                    </Styled.CardLinkGroup>
                  </Styled.CardLink>
                );
              })}
            </Styled.CardContainer>
          </Styled.ContainerRegistered>
        )}
      </Styled.Container>
    </>
  );
};

export default EventCards;
